/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Layout from "../containers/layout/layout";
import Banner from "../containers/access/banner";
import AccessInfoSection from "../containers/contact/contact-info";

const AccessPage = () => (
  <Layout>
    <Banner />
    <AccessInfoSection />
  </Layout>
);
export default AccessPage;
